import React, { Fragment, useContext, useEffect, useState } from "react"

import { useStaticQuery, graphql } from "gatsby"
import { InformationToImages } from "../utils"

import BackgroundMain from "./BackgroundMain"
import Footer from "./Footer"
import { typeLogoContext } from "./Layout"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SliderSlick from "react-slick"

import "../styles/Slider.css"
import { FooterHome } from "./FooterHome/FooterHome"
import Popup from "./PopUp/PopUp"

const Slider_mobile = ({legalsData}) => {
  const {
    setArrowLogo_mobile,
    setRightLogo_mobile,
    setCenterLogo_mobile,
  } = useContext(typeLogoContext) //allow to change navbar color

  const data = useStaticQuery(graphql`
    query MyQuery_2 {
      allHome(sort: { fields: index }) {
        nodes {
          id
          img_mobile
          index
          link
          subtitle
          text
          title
          img
          image_mobile {
            id
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
          image {
            id
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
      allFile(
        filter: { sourceInstanceName: { eq: "backgrounds_home_mobile" } }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 1600) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(page_home)/" } }
      ) {
        edges {
          node {
            frontmatter {
              path
              slide
              subtitle
              title
              paragraph
              button
              url
              slideDirection
              bgColor
              button
              typeArrowLogo_mobile
              typeRightLogo_mobile
              typeCenterLogo_mobile
            }
          }
        }
      }
    }
  `)

  const images = InformationToImages(data)

  let items = []
  data.allHome.nodes.map(function(node) {
    items.push(node)
  })

  // const changeNavbar = (next) => {
  //   setTimeout(() => { // allow not to block the buttons slider
  //     setArrowLogo_mobile(images[next].information.typeArrowLogo_mobile);
  //     setRightLogo_mobile(images[next].information.typeRightLogo_mobile);
  //     setCenterLogo_mobile(images[next].information.typeCenterLogo_mobile);
  //   }, 100);
  // }

  //allow use arrows
  const customeSlider = React.useRef()

  const handlerArrow = (e, direction) => {
    e.preventDefault()
    if (direction == "next") {
      customeSlider.current.slickNext()
    } else {
      customeSlider.current.slickPrev()
    }
  }

  const [activeSlide, setActiveSlide] = useState(0)

  /*useEffect(()=>{
    if (customeSlider.current){
      let slickListDiv = document.getElementsByClassName('slick-list')[0]
      slickListDiv.addEventListener('wheel', event => {
        event.preventDefault()
        if (customeSlider.current){
          event.deltaY > 0 ? customeSlider.current.slickNext() : customeSlider.current.slickPrev()
        }
      })
    }
  },[])*/

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    /* verticalSwiping: true,*/
    // beforeChange: (i,current) => {changeNavbar(current)}
    afterChange: current => setActiveSlide(current),
  }

  // console.log("items", images)

  return (
    <Fragment>
      <div style={{ height: "100vh", position: "relative" }}>
        <SliderSlick {...settings} ref={customeSlider}>
          {items?.map((item, index) => {
            // console.log("index", index)
            return (
              <BackgroundMain
                item={item}
                isActive={index === activeSlide}
                isMobile
                key={index}
                // imgInfo={image?.node?.childImageSharp?.fluid}
                // base={image?.information}
                handlerArrow={handlerArrow}
                legalsData={legalsData}
              />
            )
          })}
        </SliderSlick>
        <div
          className="sliderContentFooter"
          style={{ position: "absolute", bottom: "0", width: "100%" }}
        >
          <Footer handlerArrow={handlerArrow} />
        </div>
      </div>
      <FooterHome />
    </Fragment>
  )
}

export default Slider_mobile
