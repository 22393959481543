import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { InformationToImages } from "../utils/index"

import BackgroundMain from "./BackgroundMain"
import Footer from "./Footer"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SliderSlick from "react-slick"

import "../styles/Slider.css"
import { FooterHome } from "./FooterHome/FooterHome"

function Slider({legalsData}) {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allHome(sort: { fields: index }) {
        nodes {
          id
          img_mobile
          img_tablet
          index
          link
          subtitle
          text
          title
          img
          image_mobile {
            id
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
          image {
            id
            childImageSharp {
              fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
      allFile(filter: { sourceInstanceName: { eq: "backgrounds_home" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 1600) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(page_home)/" } }
      ) {
        edges {
          node {
            frontmatter {
              slide
              path
              subtitle
              title
              paragraph
              button
              url
              slideDirection
              bgColor
              button
              typeLeftLogo
              typeRightLogo
            }
          }
        }
      }
    }
  `)
 
  const images = InformationToImages(data)

  //allow use arrows
  const customeSlider = React.useRef()

  const handlerArrow = (e, direction) => {
    e.preventDefault()
    if (direction == "next") {
      customeSlider.current.slickNext()
    } else {
      customeSlider.current.slickPrev()
    }
  }

  const [activeSlide, setActiveSlide] = useState(0)

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    speed: 1100,
    verticalSwiping: true,
    afterChange: current => setActiveSlide(current),
  }

  let isScroll = false

  let items = []
  data.allHome.nodes.map(function(node) {
    items.push(node)
  })

  useEffect(() => {
    document.body.style.overflowY = "hidden"
    document.documentElement.style.overflowY = "hidden"

    if (customeSlider.current) {
      let slickListDiv = document.getElementsByClassName("slick-list")[0]
      slickListDiv.addEventListener("wheel", event => {
        event.preventDefault()

        if (customeSlider.current && !isScroll) {
          isScroll = true
          if (event.deltaY > event.deltaX) {
            event.deltaY > 0
              ? customeSlider.current.slickNext()
              : customeSlider.current.slickPrev()
            setTimeout(function() {
              isScroll = false
            }, 1000)
          } else {
            event.deltaX > 0
              ? customeSlider.current.slickNext()
              : customeSlider.current.slickPrev()
            setTimeout(function() {
              isScroll = false
            }, 3000)
          }
        }
      })

      return () => {
        slickListDiv.removeEventListener("wheel", () => {})
        document.body.style.overflowY = "unset"
        document.documentElement.style.overflowY = "unset"
      }
    }
  }, [])

  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <SliderSlick {...settings} ref={customeSlider}>
        {items.map((item, index) => (
          <BackgroundMain
            item={item}
            activeSlide={activeSlide}
            index={index}
            isActive={index === activeSlide}
            key={index}
            handlerArrow={handlerArrow}
            legalsData={legalsData}
          />
        ))}
      </SliderSlick>
      <div style={{ position: "absolute", bottom: "2vh", width: "100%" }}>
        <Footer handlerArrow={handlerArrow} />
      </div>
      <FooterHome />
    </div>
  )
}

export default Slider
