import React, { useEffect, useState } from "react"

// import BackgroundText from './BackgroundText'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import '../styles/BackgroundMain.css';
import Div100vh from 'react-div-100vh'

// import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import loadable from '@loadable/component'
import { setupTabletListener } from "../utils";
const Image = loadable(() => import('react-graceful-image'))


function BackgroundMain (props){
  // console.log("props:", props);
  const [title, setTitle] = useState('')
  useEffect(()=>{
    if (props.item){
      if (props.item.title){
        try {
          let theTilte = props.item.title.replaceAll("\\n", "\n");
          setTitle(theTilte)
        }catch (e){
          console.log(e)
          let theTilte = props.item.title.replace("\\n", "\n");
          setTitle(theTilte)
        }

      }
    }

  }, [props.item])

  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
     // Initial check and setup listener
     setupTabletListener(setIsTablet);

     // Clean up the listener on component unmount
     return () => setupTabletListener(setIsTablet);
  }, []);


  const sendSlideAnalytics = () => {
    window.dataLayer.push({
      'event' : 'GAEvent',
      'event_category': 'Carousel',
      'event_action': `${props.index}:${title}`,
      'event_label': 'Ver más',
      'interaction': 'True',
      'component_name': 'boton_ver_mas_carousel',
      'element_text': 'Ver más',
    });
  }

  return (
    <Div100vh className="row home">
      {/* img */}
      <div className="offset-md-1 col-auto homeContentImage">
        {/*<Img className={"homeImage"}
             loading={'eager'}
             fluid={props.isMobile ? props.item.image_mobile.childImageSharp.fluid : props.item.image.childImageSharp.fluid}
             backgroundColor={`#ffffff`}
        />*/}
       {/* <Image className={"homeImage"}

             src={props.isMobile ? props.item.img_mobile : props.item.img}
             backgroundColor={`#ffffff`}
        />*/}
        <img className={"homeImage"}
             alt={'homeImage'}
               src={props?.isMobile ? props?.item?.img_mobile : (isTablet && props?.item?.img_tablet) || props?.item?.img}
        />
        <p className="homeLegalesText">{props?.legalsData?.text}</p>
        {/* <p className="homeLegalesText">Beber con moderación. Prohibida su venta a menores de 18 años. Beber con moderación. Prohibida su venta a menores de 18 años. Beber con moderación. Prohibida su venta a menores de 18 años. Beber con moderación. Prohibida su venta a menores de 18 años. Beber con moderación. Prohibida su venta a menores de 18 años. Beber con moderación. Prohibida su venta a menores de 18 años. Beber con moderación.</p> */}
        <div className="overlay"></div>
      </div>
      {
        props.isMobile &&
        <div  className={'gradientBack'}></div>
      }

      {/* info */}
      <div className={'col-md-3 homeContentInfo'}>
        <div className="row">
          {/* subtitle */}
          {props?.item?.subtitle.trim() && <div className="col-auto">
            <p className="homeSubtitle">{props?.item?.subtitle}</p>
          </div>}
          {/* title */}
          <div className="col-auto">
            <p className="homeTitle">{title}</p>
          </div>
          {/* paragraph */}
          <div className="col-auto">
            <p className="homeParagraph">{props?.item?.text}</p>
          </div>
          {/* button */}
          {/*{props.base.button ?
                    <div className="col homeContentButton">
                        <AniLink to={'/' + props.item.link} cover direction="up" duration={.5} bg="#ffc311" className="homeButton">
                            ver más
                        </AniLink>
                    </div>
                : null}*/}
          <div className="col homeContentButton" onClick={sendSlideAnalytics}>
            <AniLink to={props?.item?.link} cover direction="up" duration={.5} bg="#ffc311" className="homeButton">
              ver más
            </AniLink>
          </div>
        </div>
      </div>
      <div className="gradient"/>
    </Div100vh>

  )
}

export default BackgroundMain
